import React from "react"
import { Portfolio, Posts, Layout, HomeHeader, SEO } from "../components"
import { graphql } from "gatsby"
import { MainPageHeader, PageHeader, Banner } from "../utils"
import img from "../assets/images/3122.jpg"
import img2 from "../assets/images/OCRT1X0.jpg"

const IndexPage = ({ data }) => {
  const {
    allAirtable: { nodes: portfolios },
    allMdx: { nodes: posts },
  } = data

  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`portfolio`, `Kaede Nakashima`, `@KaedeNakashima`]}
      />
      {/* <MainPageHeader img={img}>
        <HomeHeader showPerson />
      </MainPageHeader>
      <PageHeader img2={img2}>
        <HomeHeader />
      </PageHeader> */}

      <HomeHeader showPerson />
      <Posts posts={posts} title="Updated Project" />
      <Portfolio portfolios={portfolios} title="Recent Works" />
    </Layout>
  )
}

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Portfolios" } }
      limit: 4
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        data {
          image {
            localFiles {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          title
          type
          url
          details
          projectType
        }
      }
    }
    allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 3) {
      edges {
        node {
          id
        }
      }
      nodes {
        excerpt
        frontmatter {
          title
          author
          category
          date(formatString: "MMM Do, YYYY")
          image {
            childImageSharp {
              fluid {
                src
              }
            }
            id
          }
        }
      }
    }
  }
`

export default IndexPage
